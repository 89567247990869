import React from "react"

const EvenementsConviviauxContent = () => (
  <div className="p-5">
    <h3 className="text-black font-weight-bold pb-4">
      Différents événements conviviaux sont organisés au cours de l’année.
    </h3>
    <p>
      Vous restez très régulièrement en contact avec votre Business Engineer Cleeven à travers les suivis de
      projet et les déjeuners d’équipe organisés environ tous les deux mois. </p>
    <p>
      Durant l’année ont lieu des soirées conviviales comme les soirées de Noël, ou les soirées d’Eté pour
      profiter des beaux jours, sans oublier les soirées Anniversaire des sociétés ! </p>
  </div>
)

export default EvenementsConviviauxContent