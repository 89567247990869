import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/hero"
import SectionTitle from "../components/sectionTitle"
import Section from "../components/section"
import CoachingProgramContent from "../components/bouge/coachingProgramContent"
import BoostSkillsContent from "../components/bouge/boostSkillsContent"
import EvenementsConviviauxContent from "../components/bouge/evenementsConviviauxContent"
import CooptationContent from "../components/bouge/cooptationContent"

import atomIcon from "../images/icon-atom.png";
import CoachingImage from "../images/photo-coaching.png"
import BoostSkillsImage from "../images/photo-boost-your-skills.jpg"
import EvenementsImage from "../images/evenements.png";
import CooptationImage from "../images/photo-cooptation.jpg";
import SocialButtons from "../components/socialButtons"

const BougePage = () => (
  <Layout>
    <SocialButtons/>
    <SEO title="Ça bouge chez Cleeven"/>
    <div id="bouge-content" className="row">
      <Hero id="bouge" text="Les Clefs de votre réussite" />
      <SectionTitle text="Ça bouge chez Cleeven" iconImg={atomIcon} />
      <Section title="Cleeven Coaching Program" imageUrl={CoachingImage} bgColor="#ffffff" contain>
        <CoachingProgramContent/>
      </Section>
      <Section title="Boost your skills" inversed imageUrl={BoostSkillsImage} bgColor="#f3f3f3">
        <BoostSkillsContent/>
      </Section>
      <Section title="Événements conviviaux" imageUrl={EvenementsImage} bgColor="#ffffff">
        <EvenementsConviviauxContent/>
      </Section>
      <Section title="Cooptation" inversed whiteText imageUrl={CooptationImage} bgColor="#408bc7">
        <CooptationContent/>
      </Section>
    </div>
  </Layout>
)

export default BougePage