import React from "react"

const CooptationContent = () => (
  <div className="p-5">
    <h3 className="font-weight-bold pb-4">
      Cleeven a mis en place un système de cooptation pour faire participer ses collaborateurs au succès de l’entreprise !
    </h3>
    <h6>
      Le système de cooptation permet aux collaborateurs qui appuient la candidature d’une ou plusieurs de leurs connaissances d'être récompensés pour leur contribution :
    </h6>
    <ul>
      <li>
        1er candidat embauché par Cleeven : un bon cadeau de 500 €
      </li>
      <li>
        2ème candidat embauché par Cleeven (sur 12 mois glissants) : un bon cadeau de 800 €
      </li>
      <li>
        3ème candidat embauché par Cleeven (sur les 12 mois glissants) : un bon cadeau de 1 400 €
      </li>
    </ul>
    <p>
      Pour chaque recrutement effectué, Cleeven reverse également 100 € à une association caritative.
    </p>
  </div>
)

export default CooptationContent