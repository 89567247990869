import React from "react"

const BoostSkillsContent = () => (
  <div className="p-5">
    <h3 className="text-black font-weight-bold pb-4">
      Cleeven organise le partage d'expertise !
    </h3>
      <div>
        <span className="font-weight-bold text-blue">Réunions Thématiques :</span> vous êtes au coeur de votre carrière, venez partager votre expertise!
        <ul>
          <li>
            Participez en animant une réunion
          </li>
          <li>
            Partagez votre passion de la technologie en toute convivialité
          </li>
        </ul>
      </div>
      <div>
        Chaque présentation se conclut par un moment convivial entre les collaborateurs Cleeven, pour échanger sur le sujet autour d'un cocktail dînatoire.
      </div>
  </div>
)

export default BoostSkillsContent