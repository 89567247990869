import React from "react"

const CoachingProgramContent = () => (
  <div className="p-5">
    <h3 className="text-black font-weight-bold pb-4">
      Le Cleeven Coaching Program est un programme individuel de coaching mis à votre disposition pour permettre de
      développer votre potentiel sur 3 niveaux :
    </h3>
    <ul>
      <li>Upgrade your skills</li>
      <li>Improve your awareness</li>
      <li>Boost your potential</li>
    </ul>
    <h6 className="text-blue">
      6 séances de travail par an
    </h6>
    <ul>
      <li>Faire émerger des potentiels et des solutions opérationnelles pour chaque collaborateur</li>
      <li>Faire progresser</li>
    </ul>

    <h6>
      <span className="text-blue">Une bibliothèque de livres </span> est à votre disposition et chaque livre sera le
      support d’une séance de coaching.
    </h6>

    <p className="font-weight-bold mt-4">
      Rapprochez-vous de votre service RH si vous souhaitez participer au Cleeven Coaching Program
    </p>
  </div>
)

export default CoachingProgramContent